import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../context/LangContext'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import axios from 'axios'
import ReCAPTCHA from "react-google-recaptcha";

export default function Contact() {
    
  const { t } = useTranslation()
  let {language}= useContext(LangContext)
  const site = sessionStorage.getItem('desiredPage')
  const [loading, setLoading] = useState(false)

  const sendContact = async (values) => {
    setLoading(true)
    console.log(values)
   let {data} = await axios.post(`https://dashboard.almekyal.com/jsonapi/node/messages`,
    {data: {
        type: "node--message",
        attributes: values,
      }},
    {headers: {
        "Content-Type": "application/vnd.api+json", 
      }}
   )
   console.log(data)
   if (data.data?.id){
    setLoading(false)
   }
  }

  let validationCompany = yup.object({
    field_email: yup.string().required('Email is required').email('invalid email'),
    field_name: yup.string().required('Name is required').min(3,'Minimum length is 3').max(10,'Maximum length is 10'),
    field_phone: yup.string().required('Phone is required'),
    recaptcha: yup.string().required("Please verify that you are human"),
})

  let formikCompany = useFormik({
    initialValues: {
      body: '',
      title: 'New Message',
      field_email: '',
      field_name: '',
      field_phone: '',
      field_source:"websiteData",
      field_topic: '',
      recaptcha: null
    },validationSchema:validationCompany
    ,onSubmit:sendContact
  })

  let validationLabs = yup.object({
    body: yup.string().required('Message is required'),
    field_email: yup.string().required('Email is required').email('invalid email'),
    first_name: yup.string().required('First Name is required').min(3, 'Minimum length is 3').max(10, 'Maximum length is 10'),
    last_name: yup.string().required('Last Name is required').min(3, 'Minimum length is 3').max(10, 'Maximum length is 10'),
    field_phone: yup.string().required('Phone is required'),
    field_topic: yup.string().required('Subject is required'),
    recaptcha: yup.string().required("Please verify that you are human"),
})

  let formikLabs = useFormik({
    initialValues: {
      body: '',
      title: 'New Message',
      field_email: '',
      first_name: '',
      last_name: '',
      field_phone: '',
      field_source:"websiteData",
      field_topic: '',
      recaptcha: null
    },validationSchema:validationLabs
    ,  onSubmit: (values) => {
      const formData = {
        ...values,
        field_name: `${values.first_name} ${values.last_name}`, 
      };
  
      delete formData.first_name;
      delete formData.last_name;
  
      sendContact(formData)
    }
  })

  return <>
    <div dir={language==='en'? '':'rtl'} className='container-md w-90 mx-auto pb-4 pt-4'>
      <div className='mt-lg-5 py-2'>
        <div className='pt-3 pb-2'>
          <p className='fs-5 fw-bold'>{t('contactHeader.text1')}</p>
          <p><br />{t('contactHeader.text2')}<br /><br />{t('contactHeader.text3')}</p>
        </div>
        <div className='row gx-sm-4 gx-5 justify-content-center px-0 mx-0'>
          <div className="col-xl-6 col-lg-7 col-md-8">
            <div className={`mb-sm-3 d-flex flex-sm-row flex-column align-items-center justify-content-between contact-us`}>
              <Link dir='ltr' to={site==='company'? 'tel:+966558213234':'tel:+966501533348'} className='d-flex flex-column my-3 align-items-center trans3 bg-hover px-lg-4 py-lg-4 px-md-3 py-sm-3 px-3 py-3 rounded-3'><i className={`fa-solid fa-phone fs-4 text-main trans3 pb-3`}></i><p className='text-main2 trans3 text-center'>{site==='company'? '+966558213234':'966501533348'}</p></Link>
              <Link to={site==='company'?'mailto:info@almekyal.com':'mailto:lab@almekyal.com'} className='d-flex flex-column my-3 align-items-center trans3 bg-hover px-lg-4 py-lg-4 px-md-3 py-sm-3 px-3 py-3 rounded-3'><i className={`fa-solid fa-envelope fs-4 text-main trans3 pb-3`}></i><p className='text-main2 trans3 text-center'>{site==='company'? 'info@almekyal.com':'lab@almekyal.com'}</p></Link>
              <Link target='_blank' className='d-flex flex-column my-3 align-items-center trans3 bg-hover px-sm-5 py-lg-4 py-sm-3 px-5 py-3 rounded-3'><i className={`fa-brands fa-linkedin fs-4 text-main trans3 pb-3`}></i><p className='text-main2 trans3 text-center'>{t('contactHeader.linkedIn')}</p></Link>
            </div>
          </div>
        </div>
        </div>
        <p><br />{site==='company'? t('contactHeader.text4C'):t('contactHeader.text4L')}<br /><br />{t('contactHeader.text5')}</p>
        <div className='row gx-sm-4 gx-0 justify-content-center px-0 mx-0 mt-4'>
          <div className="col-xl-6 col-lg-7 col-md-8 col-sm-9">
            <div className='border rounded-2 px-3 pb-5 py-4 d-flex flex-column align-items-center'>
              <p className='fs-5 fw-bold mb-5 text-uppercase position-relative w-fit line3 text-main2'>{t('contactHeader.contactForm')}</p>
              {site==='company'? <form id='companyContact' onSubmit={formikCompany.handleSubmit} className='w-100'>
                <div className='d-flex flex-column py-2'>
                  <label htmlFor="name" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>Name</label>
                  <input onBlur={formikCompany.handleBlur} onChange={formikCompany.handleChange} id='name' placeholder='Name' type="text" name='field_name' value={formikCompany.values.field_name} className={`rounded-2 border-0 bg-body-secondary p-2 ${language==='en'? '':'text-end'}`} />
                  {formikCompany.errors.field_name && formikCompany.touched.field_name?<div className="alert alert-danger py-2 mt-3">{formikCompany.errors.field_name}</div>: ''}
                </div>
                <div className='d-flex flex-column py-2'>
                  <label htmlFor="email" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>{t('formEmail')}</label>
                  <input onBlur={formikCompany.handleBlur} onChange={formikCompany.handleChange} id='email' placeholder={t('yourEmail')} type="email" name='field_email' value={formikCompany.values.field_email} className={`rounded-2 border-0 bg-body-secondary p-2 ${language==='en'? '':'text-end'}`} />
                  {formikCompany.errors.field_email && formikCompany.touched.field_email?<div className="alert alert-danger py-2 mt-3">{formikCompany.errors.field_email}</div>: ''}
                </div>
                <div className='d-flex flex-column py-2'>
                  <label htmlFor="phone" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>{t('formPhone')}</label>
                  <input onBlur={formikCompany.handleBlur} onChange={formikCompany.handleChange} id='phone' placeholder={t('yourPhone')} type="tel" name='field_phone' value={formikCompany.values.field_phone} className={`rounded-2 border-0 bg-body-secondary p-2 ${language==='en'? '':'text-end'}`} />
                  {formikCompany.errors.field_phone && formikCompany.touched.field_phone?<div className="alert alert-danger py-2 mt-3">{formikCompany.errors.field_phone}</div>: ''}
                </div>
                <div className='d-flex flex-column py-2'>
                  <label htmlFor="body" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>{t('additionalNotes')}</label>
                  <textarea onChange={formikCompany.handleChange} id='body' placeholder={t('anyInformation')} rows={5} name='body' value={formikCompany.values.body} className={`rounded-2 border-0 bg-body-secondary p-2 ${language==='en'? '':'text-end'}`} />
                </div>
                <div className='d-flex flex-column align-items-center'>
                  <ReCAPTCHA 
                    // sitekey='6LeSlPoqAAAAABi4nS6Dfe5OVgba-tcOp9ucTDiu' 
                    sitekey='6LcRzAsrAAAAALML4Fa8teWm5xFfgPvkXSYgU31x' 
                    onChange={(value) => formikCompany.setFieldValue("recaptcha", value)} 
                  />
                  {formikCompany.errors.recaptcha? <div className="alert alert-danger py-2 mt-3">{formikCompany.errors.recaptcha}</div>:''}
                </div>
                <div className="d-flex w-100 pt-3 justify-content-center">
                  {loading? <button type='button' className='btn btn-main px-4'>
                    <i className='fas fa-spinner fa-spin'></i>
                    </button>
                    :<button disabled={!(formikCompany.isValid && formikCompany.dirty)} type='submit' className='btn btn-main px-4'>{t('send')}</button>
                    }
                </div>  
              </form>:
              <form id='labsContact' onSubmit={formikLabs.handleSubmit} className='row gx-md-5 gx-3 gy-3 pt-2'>
                <div className="col-sm-6">
                  <div className='d-flex flex-column'>
                    <label htmlFor="first" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>{t('firstName')}</label>
                    <input onBlur={formikLabs.handleBlur} onChange={formikLabs.handleChange} id='first' placeholder={t('yourFirstName')} type="text" name='first_name' value={formikCompany.values.first_name} className={`rounded-2 border-0 bg-body-secondary p-2 ${language==='en'? '':'text-end'}`} />
                    {formikLabs.errors.first_name && formikLabs.touched.first_name?<div className="alert alert-danger py-2 mt-3">{formikLabs.errors.first_name}</div>: ''}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className='d-flex flex-column'>
                    <label htmlFor="last" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>{t('lastName')}</label>
                    <input onBlur={formikLabs.handleBlur} onChange={formikLabs.handleChange} id='last' placeholder={t('yourLastName')} type="text" name='last_name' value={formikCompany.values.last_name} className={`rounded-2 border-0 bg-body-secondary p-2 ${language==='en'? '':'text-end'}`} />
                    {formikLabs.errors.last_name && formikLabs.touched.last_name?<div className="alert alert-danger py-2 mt-3">{formikLabs.errors.last_name}</div>: ''}
                  </div>
                </div>
                <div className="col-12">
                  <div className='d-flex flex-column'>
                    <label htmlFor="email" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>{t('formEmail')}</label>
                    <input onBlur={formikLabs.handleBlur} onChange={formikLabs.handleChange} id='email' placeholder={t('yourEmail')} type="email" name='field_email' value={formikLabs.values.field_email} className={`rounded-2 border-0 bg-body-secondary p-2 ${language==='en'? '':'text-end'}`} />
                    {formikLabs.errors.field_email && formikLabs.touched.field_email?<div className="alert alert-danger py-2 mt-3">{formikLabs.errors.field_email}</div>: ''}
                  </div>
                </div>
                <div className="col-12">
                  <div className='d-flex flex-column'>
                    <label htmlFor="tel" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>{t('formPhone')}</label>
                    <input onBlur={formikLabs.handleBlur} onChange={formikLabs.handleChange} id='tel' placeholder={t('yourPhone')} type="tel" name='field_phone' value={formikLabs.values.field_phone} className={`rounded-2 border-0 bg-body-secondary p-2 ${language==='en'? '':'text-end'}`} />
                    {formikLabs.errors.field_phone && formikLabs.touched.field_phone?<div className="alert alert-danger py-2 mt-3">{formikLabs.errors.field_phone}</div>: ''}
                  </div>
                </div>
                <div className="col-12">
                  <div className='d-flex flex-column'>
                    <label htmlFor="subject" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>{t('subject')}</label>
                    <input onBlur={formikLabs.handleBlur} onChange={formikLabs.handleChange} id='subject' placeholder={t('yourSubject')} type="text" name='field_topic' value={formikLabs.values.field_topic} className={`rounded-2 border-0 bg-body-secondary p-2 ${language==='en'? '':'text-end'}`} />
                    {formikLabs.errors.field_topic && formikLabs.touched.field_topic?<div className="alert alert-danger py-2 mt-3">{formikLabs.errors.field_topic}</div>: ''}
                  </div>
                </div>
                <div className="col-12">
                  <div className='d-flex flex-column'>
                    <label htmlFor="message" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>{t('message')}</label>
                    <textarea onBlur={formikLabs.handleBlur} onChange={formikLabs.handleChange} id='message' placeholder={t('yourMessage')} rows={5} name='body' value={formikLabs.values.body} className={`rounded-2 border-0 bg-body-secondary p-2 ${language==='en'? '':'text-end'}`} />
                    {formikLabs.errors.body && formikLabs.touched.body?<div className="alert alert-danger py-2 mt-3">{formikLabs.errors.body}</div>: ''}
                  </div>
                </div>
                <div className='d-flex flex-column align-items-center'>
                  <ReCAPTCHA 
                    // sitekey='6LeSlPoqAAAAABi4nS6Dfe5OVgba-tcOp9ucTDiu' 
                    sitekey='6LcRzAsrAAAAALML4Fa8teWm5xFfgPvkXSYgU31x' 
                    onChange={(value) => formikLabs.setFieldValue("recaptcha", value)} 
                  />
                  {formikLabs.errors.recaptcha? <div className="alert alert-danger py-2 mt-3">{formikLabs.errors.recaptcha}</div>:''}
                </div>
                <div className="d-flex w-100 pt-3 justify-content-center">
                  {loading? <button type='button' className='btn btn-main px-4'>
                    <i className='fas fa-spinner fa-spin'></i>
                    </button>
                    :<button disabled={!(formikLabs.isValid && formikLabs.dirty)} type='submit' className='btn btn-main px-4'>{t('send')}</button>
                  }
                </div>
              </form>}
            </div>
          </div>
        </div>
        <p className='text-center py-3 fw-bold'><br />{t('contactHeader.text6')}</p>
    </div>
  </>
}
