import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../components/context/LangContext'
import { Link } from 'react-router-dom'
import serviceLab1_1 from '../assets/images/فحص وتقييم المباني صورة 2.webp'
import serviceLab1_2 from '../assets/images/مشروع تبوك صورة 4.webp'
import serviceLab2_1 from '../assets/images/اختبار عينات كور خرساني.webp'
import serviceLab2_2 from '../assets/images/أخذ كور من طبقة اسفلتية 1.webp'
import serviceLab3_1 from '../assets/images/حفارات9.webp'
import serviceLab3_2 from '../assets/images/حفارات 8.webp'
import serviceLab4_1 from '../assets/images/pic1.webp'
import serviceLab4_2 from '../assets/images/pic3.webp'
import serviceLab5_1 from '../assets/images/فقرة الدرسات الفنية 2.webp'
import serviceLab5_2 from '../assets/images/فقرة الدرسات الفنية الخاصة.webp'
import serviceLab4_3 from '../assets/images/pic7.webp'
import serviceCompany1_1 from '../assets/images/5.webp'
import serviceCompany1_2 from '../assets/images/ابوبندر_page-0001.webp'
import serviceCompany2_1 from '../assets/images/WhatsApp Image 2024-07-25 at 9.13.18 AM.webp'
import serviceCompany2_2 from '../assets/images/WhatsApp Image 2024-07-25 at 9.00.05 AM.webp'
import serviceCompany3_1 from '../assets/images/IMG-20230417-WA0026.webp'
import serviceCompany3_2 from '../assets/images/WhatsApp Image 2023-04-01 at 15.30.45.webp'
import serviceCompany4_1 from '../assets/images/IMG-20241121-WA0020.webp'
import serviceCompany4_2 from '../assets/images/DSCN1225.webp'
import serviceCompany5_1 from '../assets/images/WhatsApp Image 2024-03-05 at 7.40.00 AM (1).webp'
import serviceCompany5_2 from '../assets/images/pan and profile road0_page-0001.webp'
import serviceCompany6_1 from '../assets/images/WhatsApp Image 2024-07-29 at 9.19.40 AM.webp'
import serviceCompany6_2 from '../assets/images/WhatsApp Image 2024-09-30 at 11.16.56_65601b4d.webp'
import serviceCompany7_1 from '../assets/images/WhatsApp Image 2024-09-22 at 16.51.39_0cd91c02.webp'
import serviceCompany7_2 from '../assets/images/WhatsApp Image 2024-07-28 at 8.49.54 PM.webp'
import serviceCompany8_1 from '../assets/images/WhatsApp Image 2022-06-23 at 1.09.57 PM.webp'
import serviceCompany8_2 from '../assets/images/مشروع تبوك صورة 4.webp'
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/autoplay"
import 'swiper/css/pagination'
import 'swiper/swiper-bundle.css';
import { Pagination } from 'swiper/modules'

export default function Services() {

  const { t } = useTranslation()
  let {language}= useContext(LangContext)
  const site = sessionStorage.getItem('desiredPage')

  return <>
  
    <div dir={language==='en'? '':'rtl'}>
                                        {/* header */}
      <div className="header-img py-5 overflow-hidden">
        <div className='container-md w-95 mx-auto d-flex h-100 flex-column align-items-center justify-content-center'>
          {site==='company'? '':<p data-aos="zoom-in" className='position-relative line fs-2 fw-bold text-black d-flex align-items-center justify-content-center pb-3 mt-3 mb-5'>{t('servicesHeader')}</p>}
          <div data-aos="fade-up" data-aos-delay="200" className={`flex-wrap justify-content-center ${site==='company'? 'lh-md fw-bold d-flex fs-5':'d-md-flex d-none'}`}>
            <Link to={'/'}className='text-hover trans3'>{t('navHome')}<span className='text-black'>&nbsp;/&nbsp;</span></Link>
            <p className='text-secondary'>{t('services')}</p>
          </div>
        </div>
      </div>

      {/* contents */}
      <div className='container-md w-95 py-5 mx-auto'>
      {site==='company'? '':<p data-aos="fade-up" data-aos-delay="600" className={`text-light-emphasis mt-5 mb-3 text-uppercase fs-6 text-center`}>{t('servicesText1')}</p>}
        <p data-aos="fade-up" data-aos-delay="800" className='text-center fs-4 fw-bold mx-auto container-lg px-lg-5 px-0'>{site==='company'? t('servicesText1Company'):t('servicesText2')}</p>
      </div>
      {site==='company'? <div className='container-md w-90 pb-5 mx-auto row g-3 px-0 justify-content-center'>
            <div data-aos="zoom-in" data-aos-delay="100" data-aos-offset="0" data-aos-easing="ease-in-back" className="col-xl-3 col-lg-4 col-md-6">
              <Link to={'/services/architectural'} className='w-100 h-100 scale2 cardd text-center border border-light-subtle shadow-hover bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                <hr className='top-line position-absolute z-2 m-0 top-0 end-0 start-0 opacity-100' />
                <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                  <div className="carousel-inner">
                    <div className="carousel-item w-100 rem-15 overflow-hidden active">
                      <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany1_1} alt="service" />
                    </div>
                    <div className="carousel-item w-100 rem-15 overflow-hidden">
                      <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany1_2} alt="service" />
                    </div>
                  </div>
                </div>
                <h4 className='py-xl-4 py-2 my-2 px-4 text-hover trans3 scale2'>{t('navArchitectural')}</h4>
                <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                <p className='p-4 my-2 text-secondary text-justify'>{t('service1CompanySentence')}</p>
                <p className={`btn d-flex align-items-center justify-content-center`}><p className='text-body-secondary small text-hover trans3 fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></p>
              </Link>
            </div>
            <div data-aos="zoom-in" data-aos-delay="100" data-aos-offset="0" data-aos-easing="ease-in-back" className="col-xl-3 col-lg-4 col-md-6">
              <Link to={'/services/hydrological'} className='w-100 h-100 scale2 cardd text-center border border-light-subtle shadow-hover bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                <hr className='top-line position-absolute z-2 m-0 top-0 end-0 start-0 opacity-100' />
                <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                  <div className="carousel-inner">
                    <div className="carousel-item w-100 rem-15 overflow-hidden active">
                      <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany2_1} alt="service" />
                    </div>
                    <div className="carousel-item w-100 rem-15 overflow-hidden">
                      <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany2_2} alt="service" />
                    </div>
                  </div>
                </div>
                <h4 className='py-xl-4 py-2 my-2 px-4 text-hover trans3 scale2'>{t('navHydrological')}</h4>
                <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                <p className='p-4 my-2 text-secondary text-justify'>{t('service2CompanySentence')}</p>
                <p className={`btn d-flex align-items-center justify-content-center`}><p className='text-body-secondary text-hover trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></p>
              </Link>
            </div>
            <div data-aos="zoom-in" data-aos-delay="100" data-aos-offset="0" data-aos-easing="ease-in-back" className="col-xl-3 col-lg-4 col-md-6">
              <Link to={'/services/GIS'} className='w-100 h-100 scale2 cardd text-center border border-light-subtle shadow-hover bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                <hr className='top-line position-absolute z-2 m-0 top-0 end-0 start-0 opacity-100' />
                <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                  <div className="carousel-inner">
                    <div className="carousel-item w-100 rem-15 overflow-hidden active">
                      <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany3_1} alt={t('navSupervision')} />
                    </div>
                    <div className="carousel-item w-100 rem-15 overflow-hidden">
                      <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany3_2} alt={t('navSupervision')} />
                    </div>
                  </div>
                </div>
                <h4 className='py-xl-4 py-2 my-2 px-4 text-hover trans3 scale2'>{t('navGIS')}</h4>
                <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                <p className='p-4 my-2 text-secondary text-justify'>{t('service3CompanySentence')}</p>
                <p className={`btn d-flex align-items-center justify-content-center`}><p className='text-body-secondary text-hover trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></p>
              </Link>
            </div>
            <div data-aos="zoom-in" data-aos-delay="100" data-aos-offset="0" data-aos-easing="ease-in-back" className="col-xl-3 col-lg-4 col-md-6">
              <Link to={'/services/supervision'} className='w-100 h-100 scale2 cardd text-center border border-light-subtle shadow-hover bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                <hr className='top-line position-absolute z-2 m-0 top-0 end-0 start-0 opacity-100' />
                <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                  <div className="carousel-inner">
                    <div className="carousel-item w-100 rem-15 overflow-hidden active">
                      <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany4_1} alt="service" />
                    </div>
                    <div className="carousel-item w-100 rem-15 overflow-hidden">
                      <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany4_2} alt="service" />
                    </div>
                  </div>
                </div>
                <h4 className='py-xl-4 py-2 my-2 px-4 text-hover trans3 scale2'>{t('navSupervision')}</h4>
                <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                <p className='p-4 my-2 text-secondary text-justify'>{t('service4CompanySentence')}</p>
                <p className={`btn d-flex align-items-center justify-content-center`}><p className='text-body-secondary text-hover trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></p>
              </Link>
            </div>
            <div data-aos="zoom-in" data-aos-delay="100" data-aos-offset="0" data-aos-easing="ease-in-back" className="col-xl-3 col-lg-4 col-md-6">
              <Link to={'/services/infrastructure'} className='w-100 h-100 scale2 cardd text-center border border-light-subtle shadow-hover bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                <hr className='top-line position-absolute z-2 m-0 top-0 end-0 start-0 opacity-100' />
                <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                  <div className="carousel-inner">
                    <div className="carousel-item w-100 rem-15 overflow-hidden active">
                      <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany5_1} alt="service" />
                    </div>
                    <div className="carousel-item w-100 rem-15 overflow-hidden">
                      <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany5_2} alt="service" />
                    </div>
                  </div>
                </div>
                <h4 className='py-xl-4 py-2 my-2 px-4 text-hover trans3 scale2'>{t('navInfrastructure')}</h4>
                <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                <p className='p-4 my-2 text-secondary text-justify'>{t('service5CompanySentence')}</p>
                <p className={`btn d-flex align-items-center justify-content-center`}><p className='text-body-secondary text-hover trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></p>
              </Link>
            </div>
            <div data-aos="zoom-in" data-aos-delay="100" data-aos-offset="0" data-aos-easing="ease-in-back" className="col-xl-3 col-lg-4 col-md-6">
              <Link to={'/services/transport'} className='w-100 h-100 scale2 cardd text-center border border-light-subtle shadow-hover bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                <hr className='top-line position-absolute z-2 m-0 top-0 end-0 start-0 opacity-100' />
                <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                  <div className="carousel-inner">
                    <div className="carousel-item w-100 rem-15 overflow-hidden active">
                      <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany6_1} alt="service" />
                    </div>
                    <div className="carousel-item w-100 rem-15 overflow-hidden">
                      <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany6_2} alt="service" />
                    </div>
                  </div>
                </div>
                <h4 className='py-xl-4 py-2 my-2 px-4 text-hover trans3 scale2'>{t('navTransport')}</h4>
                <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                <p className='p-4 my-2 text-secondary text-justify'>{t('service6CompanySentence')}</p>
                <p className={`btn d-flex align-items-center justify-content-center`}><p className='text-body-secondary text-hover trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></p>
              </Link>
            </div>
            <div data-aos="zoom-in" data-aos-delay="100" data-aos-offset="0" data-aos-easing="ease-in-back" className="col-xl-3 col-lg-4 col-md-6">
              <Link to={'/services/electrical'} className='w-100 h-100 scale2 cardd text-center border border-light-subtle shadow-hover bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                <hr className='top-line position-absolute z-2 m-0 top-0 end-0 start-0 opacity-100' />
                <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                  <div className="carousel-inner">
                    <div className="carousel-item w-100 rem-15 overflow-hidden active">
                      <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany7_1} alt="service" />
                    </div>
                    <div className="carousel-item w-100 rem-15 overflow-hidden">
                      <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany7_2} alt="service" />
                    </div>
                  </div>
                </div>
                <h4 className='py-xl-4 py-2 my-2 px-4 text-hover trans3 scale2'>{t('navElectrical')}</h4>
                <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                <p className='p-4 my-2 text-secondary text-justify'>{t('service7CompanySentence')}</p>
                <p className={`btn d-flex align-items-center justify-content-center`}><p className='text-body-secondary text-hover trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></p>
              </Link>
            </div>
            <div data-aos="zoom-in" data-aos-delay="100" data-aos-offset="0" data-aos-easing="ease-in-back" className="col-xl-3 col-lg-4 col-md-6">
              <Link to={'/services/evaluation'} className='w-100 h-100 scale2 cardd text-center border border-light-subtle shadow-hover bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                <hr className='top-line position-absolute z-2 m-0 top-0 end-0 start-0 opacity-100' />
                <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                  <div className="carousel-inner">
                    <div className="carousel-item w-100 rem-15 overflow-hidden active">
                      <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany8_1} alt="service" />
                    </div>
                    <div className="carousel-item w-100 rem-15 overflow-hidden">
                      <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany8_2} alt="service" />
                    </div>
                  </div>
                </div>
                <h4 className='py-xl-4 py-2 my-2 px-4 text-hover trans3 scale2'>{t('navEvaluation')}</h4>
                <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                <p className='p-4 my-2 text-secondary text-justify'>{t('service8CompanySentence')}</p>
                <p className={`btn d-flex align-items-center justify-content-center`}><p className='text-body-secondary text-hover trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></p>
              </Link>
            </div>
        </div>:
      <div className="row align-items-center mx-0 gx-0 gy-3 px-lg-0 px-md-5 px-3 py-5">
        <div className="col-xl-5 col-lg-4">
          <div className='container-lg w-95 px-xl-5 px-lg-3 px-0'>
            <h3 data-aos="fade-up" data-aos-delay="300" className='position-relative line2 pb-3'>{t('aboutHeaderLaboratories')}</h3>
            <p data-aos="fade-up" data-aos-delay="500" className='pt-5 pb-3 text-secondary text-justify'>{t('aboutSentenceLaboratories')}</p>
            <Link to={'/services/geotechnical'}>
              <div className='text-hover trans3 scale'>
                <div data-aos="fade-up" data-aos-delay="600" className="d-flex my-3">
                  <div className={`bg-main2 h-fit rounded-circle ${language==='en'? 'me-3':'ms-3'}`}>
                    <i className="fa-solid fa-check small-circle-icon d-flex align-items-center justify-content-center text-white small"></i>
                  </div>
                  <p>{t('navGeotechnical')}</p>
                </div>
              </div>
            </Link>
            <Link to={'/services/soil'}>
              <div className='text-hover trans3 scale'>
                <div data-aos="fade-up" data-aos-delay="800" className="d-flex my-3">
                  <div className={`bg-main2 h-fit rounded-circle ${language==='en'? 'me-3':'ms-3'}`}>
                    <i className="fa-solid fa-check small-circle-icon d-flex align-items-center justify-content-center text-white small"></i>
                  </div>
                  <p>{t('navSoil')}</p>
                </div>
               </div>
            </Link>
            <Link to={'/services/chemical-analysis'}>
              <div className='text-hover trans3 scale'>
                <div data-aos="fade-up" data-aos-delay="1000" className="d-flex my-3">
                  <div className={`bg-main2 h-fit rounded-circle ${language==='en'? 'me-3':'ms-3'}`}>
                    <i className="fa-solid fa-check small-circle-icon d-flex align-items-center justify-content-center text-white small"></i>
                  </div>
                  <p>{t('navChemical')}</p>
                </div>
               </div>
            </Link>
            <Link to={'/services/building'}>
              <div className='text-hover trans3 scale'>
                <div data-aos="fade-up" data-aos-delay="1200" className="d-flex my-3">
                  <div className={`bg-main2 h-fit rounded-circle ${language==='en'? 'me-3':'ms-3'}`}>
                    <i className="fa-solid fa-check small-circle-icon d-flex align-items-center justify-content-center text-white small"></i>
                  </div>
                  <p>{t('navBuilding')}</p>
                </div>
               </div>
            </Link>
            <Link to={'/services/soil-studies'}>
              <div className='text-hover trans3 scale'>
                <div data-aos="fade-up" data-aos-delay="1400" className="d-flex my-3">
                  <div className={`bg-main2 h-fit rounded-circle ${language==='en'? 'me-3':'ms-3'}`}>
                    <i className="fa-solid fa-check small-circle-icon d-flex align-items-center justify-content-center text-white small"></i>
                  </div>
                  <p>{t('navStudies')}</p>
                </div>
               </div>
            </Link>
          </div>
        </div>
        <div data-aos={language==='en'? 'fade-left':'fade-right'} data-aos-delay="600" className="col-xl-7 col-lg-8">
        <div>
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper pb-5 services-wrapper cursor-grab"
            breakpoints={{
              992: {
                slidesPerView: 'auto',
              },
              768: {
                slidesPerView: 2,
              },
            }}
            dir='rtl'
          >
              <SwiperSlide>
                <Link to={'/services/geotechnical'} className='scale2 cardd text-center border border-light-subtle bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                  <hr className='top-line position-absolute z-3 m-0 top-0 end-0 start-0 opacity-100' />
                  <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                    <div className="carousel-inner">
                      <div className="carousel-item w-100 rem-15 overflow-hidden active">
                        <img loading='lazy' className='scale2 w-100 h-100' src={serviceLab3_1} alt="service" />
                      </div>
                      <div className="carousel-item w-100 rem-15 overflow-hidden">
                        <img loading='lazy' className='scale2 w-100 h-100' src={serviceLab3_2} alt="service" />
                      </div>
                    </div>
                  </div>
                  <h4 className='py-xl-4 py-2 my-2 px-4 text-hover scale2 trans3'>{t('navGeotechnical')}</h4>
                  <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                  <p className='p-4 my-2 text-secondary text-justify'>{t('service1LabSentence')}</p>
                  <p className={`btn d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><p className='text-body-secondary text-hover trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></p>
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link to={'/services/soil'} className='scale2 cardd text-center border border-light-subtle bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                  <hr className='top-line position-absolute z-3 m-0 top-0 end-0 start-0 opacity-100' />
                  <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                    <div className="carousel-inner">
                      <div className="carousel-item w-100 rem-15 overflow-hidden active">
                        <img loading='lazy' className='scale2 w-100 h-100' src={serviceLab2_2} alt="service" />
                      </div>
                      <div className="carousel-item w-100 rem-15 overflow-hidden">
                        <img loading='lazy' className='scale2 w-100 h-100' src={serviceLab2_1} alt="service" />
                      </div>
                    </div>
                  </div>
                  <h4 className='py-xl-4 py-2 my-2 px-4 text-hover scale2 trans3'>{t('navSoil')}</h4>
                  <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                  <p className='p-4 my-2 text-secondary text-justify'>{t('service3LabSentence')}</p>
                  <p className={`btn d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><p className='text-body-secondary text-hover trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></p>
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link to={'/services/chemical-analysis'} className='scale2 cardd text-center border border-light-subtle bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                  <hr className='top-line position-absolute z-3 m-0 top-0 end-0 start-0 opacity-100' />
                  <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                    <div className="carousel-inner">
                      <div className="carousel-item w-100 rem-15 overflow-hidden active">
                        <img loading='lazy' className='scale2 w-100 h-100' src={serviceLab4_1} alt={t('navChemical')} />
                      </div>
                      <div className="carousel-item w-100 rem-15 overflow-hidden">
                        <img loading='lazy' className='scale2 w-100 h-100' src={serviceLab4_2} alt={t('navChemical')} />
                      </div>
                      <div className="carousel-item w-100 rem-15 overflow-hidden">
                        <img loading='lazy' className='scale2 w-100 h-100' src={serviceLab4_3} alt={t('navChemical')} />
                      </div>
                    </div>
                  </div>
                  <h4 className='py-xl-4 py-2 my-2 px-4 text-hover scale2 trans3'>{t('navChemical')}</h4>
                  <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                  <p className='p-4 my-2 text-secondary text-justify'>{t('service2LabSentence')}</p>
                  <p className={`btn d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><p className='text-body-secondary text-hover trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></p>
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link to={'/services/building'} className='scale2 cardd text-center border border-light-subtle bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                  <hr className='top-line position-absolute z-3 m-0 top-0 end-0 start-0 opacity-100' />
                  <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                    <div className="carousel-inner">
                      <div className="carousel-item w-100 rem-15 overflow-hidden active">
                        <img loading='lazy' className='scale2 w-100 h-100' src={serviceLab1_1} alt="service" />
                      </div>
                      <div className="carousel-item w-100 rem-15 overflow-hidden">
                        <img loading='lazy' className='scale2 w-100 h-100' src={serviceLab1_2} alt="service" />
                      </div>
                    </div>
                  </div>
                  <h4 className='py-xl-4 py-2 my-2 px-4 text-hover scale2 trans3'>{t('navBuilding')}</h4>
                  <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                  <p className='p-4 my-2 text-secondary text-justify'>{t('service4LabSentence')}</p>
                  <p className={`btn d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><p className='text-body-secondary text-hover trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></p>
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                  <Link to={'/services/soil-studies'} className='scale2 cardd text-center border border-light-subtle bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                    <hr className='top-line position-absolute z-3 m-0 top-0 end-0 start-0 opacity-100' />
                    <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                      <div className="carousel-inner">
                        <div className="carousel-item w-100 rem-15 overflow-hidden active">
                          <img loading='lazy' className='scale2 w-100 h-100' src={serviceLab5_1} alt="service" />
                        </div>
                        <div className="carousel-item w-100 rem-15 overflow-hidden">
                          <img loading='lazy' className='scale2 w-100 h-100' src={serviceLab5_2} alt="service" />
                        </div>
                      </div>
                    </div>
                    <h4 className='py-xl-4 py-2 my-2 px-4 text-hover scale2 trans3'>{t('navStudies')}</h4>
                    <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                    <p className='p-4 my-2 text-secondary text-justify'>{t('service5LabSentence')}</p>
                    <Link className={`btn d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><p className='text-body-secondary text-hover trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></Link>
                </Link>
              </SwiperSlide>
          </Swiper>
        </div>
        </div>
      </div>}
    </div>
  </>
}
